import React from "react";

import { AiFillCamera } from "react-icons/ai";
import Header from "../component/Header";

function Settings() {
  return (
    <div className="web">
      <Header />
      <div className="mt-3 p-3">
        <p className="clr" style={{ fontSize: "20px" }}>
          General Settings
        </p>
        <div className="row">
          <div className="col-5">
            <div className="changepassword">
              <h5 className="clr mt-3">Change password</h5>
              <div className="d-flex mt-2">
                <div className="col-6">
                  <p>Enter Current Password: </p>
                </div>
                <div className="col-6">
                  <input class="form-control" style={{width:'210px'}}/>
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="col-6">
                  <p>Enter New Password: </p>
                </div>
                <div className="col-6 mt-2">
                  <input class="form-control" style={{width:'210px'}} />
                </div>
              </div>
              <div className="d-flex mt-2">
                <div className="col-6">
                  <p>Confirm New Password: </p>
                </div>
                <div className="col-6">
                  <input class="form-control" style={{width:'210px'}}/>
                  <p style={{float:"right",color:"black",justifyContent:"end"}}>Forgot password</p>
                </div>
              </div>
              <div
                className=" mt-3"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <p className="save">Save</p>
                <p className="cancle">Cancel</p>
              </div>
            </div>
          </div>
          <div
            className="col-5 "
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-2 mt-5">
              <div className="addhospital">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR60Xvta-CNh_6CQEry6eFuRYHVrKFiJWMhxw&usqp=CAU"
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                  }}
                />
                <div>
                  <AiFillCamera style={{ fontSize: "25px" }} />
                </div>
                <p style={{ fontSize: "13px" }}> Profile Photo</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
