import React from "react";
import { IoNotificationsSharp } from "react-icons/io5";
import { FaRegUserCircle } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

function Header() {
  const popover = (
    <Popover id="popover-basic">
      {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
      <Popover.Body>
        <p>Edit Profile</p>
        <p>Settings</p>
        <a href="/">
          <p>logout</p>
        </a>
      </Popover.Body>
    </Popover>
  );
  return (
    <div>
      <div
        style={{
          // backgroundColor: "#0d6efd",
          height: "45px",
        }}
      >
        {/* <p style={{marginTop:"5px"}}><b>Hi Sheik</b></p>  */}
        <div style={{ textAlign: "end" }}>
          {/* <input placeholder="Search"/> */}
          <IoNotificationsSharp
            style={{
              marginRight: "25px",
              fontSize: "22px",
              marginTop: "5px",
            }}
          />
          <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
            <img
              src="/images/profile.jpg"
              style={{
                marginRight: "15px",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                cursor:"pointer",
                padding:"8px"
              }}
            />
          </OverlayTrigger>
        </div>
      </div>

      <div>
        <i class="bi bi-bell"></i>
      </div>
    </div>
  );
}

export default Header;
