export const allData = [
  {
    slno: "0",
    invoiceid: "0001",
    Patient: "Sumanraj",
    paymenttype: "Eye strain",
    paiddate: "20/02/2023",
    paidamount: "200",
    paymentmode: "Paid",
    
  },
  {
    slno: "0",
    invoiceid: "0001",
    Patient: "Sumanraj",
    paymenttype: "Eye strain",
    paiddate: "20/02/2023",
    paidamount: "200",
    paymentmode: "Paid",
  
  },
  {
    slno: "0",
    invoiceid: "0001",
    Patient: "Sumanraj",
    paymenttype: "Eye strain",
    paiddate: "20/02/2023",
    paidamount: "200",
    paymentmode: "Paid",
   
  },
  {
    slno: "0",
    invoiceid: "0001",
    Patient: "Sumanraj",
    paymenttype: "Eye strain",
    paiddate: "20/02/2023",
    paidamount: "200",
    paymentmode: "Paid",
   
  },
  {
    slno: "0",
    invoiceid: "0001",
    Patient: "Sumanraj",
    paymenttype: "Eye strain",
    paiddate: "20/02/2023",
    paidamount: "200",
    paymentmode: "Paid",
   
  },
  {
    slno: "0",
    invoiceid: "0001",
    Patient: "Sumanraj",
    paymenttype: "Eye strain",
    paiddate: "20/02/2023",
    paidamount: "200",
    paymentmode: "Paid",
    
  },
  {
    slno: "0",
    invoiceid: "0001",
    Patient: "Sumanraj",
    paymenttype: "Eye strain",
    paiddate: "20/02/2023",
    paidamount: "200",
    paymentmode: "Paid",
  
  },
  {
    slno: "0",
    invoiceid: "0001",
    Patient: "Sumanraj",
    paymenttype: "Eye strain",
    paiddate: "20/02/2023",
    paidamount: "200",
    paymentmode: "Paid",
   
  },
];
