import React, { useState } from "react";
import Header from "../component/Header";
import { Card } from "react-bootstrap";
import { BsSearch,BsCurrencyDollar } from "react-icons/bs";
import Chart from "react-apexcharts";

function Dashboard() {
  const [state, setState] = useState({
    options: {
      colors: ["#008000", "#0d6efd"],
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep","Oct","Nov","Dec"],
      },
    },
    series: [
      {
        name: "Appointment",
        data: [30, 40, 45, 50, 49, 60, 70, 91,92,33,22,100],
      },
      {
        name: "Patient Treated",
        data: [3, 60, 35, 80, 49, 70, 20, 81,25,66,91,99],
      },
    ],
  });
  return (
    <div className="web">
      <Header />
      <div className="p-3 ">
        <div style={{ display: "flex", gap: "10px" }}>
          <Card className="cards ">
            <div className="d-flex p-3">
              <div className="col-md-10">
                <p><BsCurrencyDollar/>
                  <b>150,000</b>
                </p>
                <p>Total earnings</p>
              </div>
              <div className="col-md-2">
                <img src="/images/icons8-patients-64.png" className="img" />
              </div>
            </div>
          </Card>

          <Card className="cards">
            <div className="d-flex p-3">
              <div className="col-md-10">
                <p>
                  <b>70</b>
                </p>
                <p>Top Doctors</p>
              </div>
              <div className="col-md-2">
                <img src="/images/icons8-doctors-64.png" className="img" />
              </div>
            </div>
          </Card>

          <Card className="cards">
            <div className="d-flex p-3">
              <div className="col-md-10">
                <p>
                  <b>2201</b>
                </p>
                <p>Hospitals</p>
              </div>
              <div className="col-2">
                <img src="/images/icons8-stethoscope-64.png" className="img" />
              </div>
            </div>
          </Card>
          <Card className="cards ">
            <div className="d-flex p-3">
              <div className="col-md-10">
                <p>
                  <b>3500</b>
                </p>
                <p>Mawiddi App users</p>
              </div>
              <div className="col-md-2">
                <img src="/images/icons8-appointments-64.png" className="img" />
              </div>
            </div>
          </Card>
        </div>
      
        <div className="row" style={{ marginTop: "25px" }}>
          <div className="">
            <p>
              <b> Reports</b>
            </p>
           
        <div>
        <div className="row">
      
        <div >
          <Chart
            options={state.options}
            series={state.series}
            type="area"
            width="100%"
            height="400px"
          />
        </div>
      
      </div>
        </div>
           
          </div>
          {/* <div className="col-4" style={{width:"36.6%"}}>
            <Card className="recent">
              <p
                style={{
                  color: "#0d6efd",

                  marginLeft: "10px",
                  marginTop: "10px",
                }}
              >
                Recent Appointments
              </p>

              <table className="table">
                <thead style={{ fontSize: "12px" }}>
                  <tr>
                    <th>Patient Name</th>
                    <th>Date & Time</th>
                    <th>Place</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "12px" }}>
                  <tr>
                    <td>John</td>
                    <td>10:30 AM</td>
                    <td>Doe</td>
                    <td>Confirmed</td>
                  </tr>
                  <tr>
                    <td>Mary</td>
                    <td>12:30 PM</td>
                    <td>Moe</td>
                    <td>Pending</td>
                  </tr>
                  <tr>
                    <td>July</td>
                    <td>05:00 PM</td>
                    <td>Dooley</td>
                    <td>Confirmed</td>
                  </tr>
                  <tr>
                    <td>bracewell</td>
                    <td>10:30 AM</td>
                    <td>Doe</td>
                    <td>Confirmed</td>
                  </tr>
                  <tr>
                    <td>topely</td>
                    <td>12:30 PM</td>
                    <td>Moe</td>
                    <td>Pending</td>
                  </tr>
                  <tr>
                    <td>Mahamd</td>
                    <td>05:00 PM</td>
                    <td>Dooley</td>
                    <td>Confirmed</td>
                  </tr>
                </tbody>
              </table>
              <div>
                <p
                  style={{
                    color: "#0d6efd",
                    fontSize: "12px",
                    marginLeft: "20px",
                    paddingBottom: "10px",
                  }}
                >
                  <BsSearch /> View All Appointments
                </p>
              </div>
            </Card>
          </div> */}

       
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
