import React from "react";
import Header from "../component/Header";
import { MdLocationOn } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

function Hospital() {
  return (
    <div className="web">
      <Header />
      <div className="p-3">
        <a href="/addhospital">
          <Button style={{ float: "right" }}>ADD Hospital</Button>
        </a>
        <h4>Hospitals</h4>

        <div className="row w-100 m-auto mt-4">
          <div className="col-md-3">
            <Link to="/hospitaldetails">
              <div className="hspd">
                <img
                  src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                  style={{ borderRadius: "50%", width: "80px", height: "80px" }}
                />
                <h5>Naseem Healthcare</h5>
                <p> +974 4465 2121</p>
                <div className="d-flex">
                  <p style={{ fontSize: "12px", marginTop: "10px" }}>
                    {" "}
                    <MdLocationOn style={{ fontSize: "16px" }} />
                    Al Hilal West Al Emadi Business Center 3rd Floor Room No:53,
                    C Ring Rd, Doha, Qatar
                  </p>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-md-3">
            <div className="hspd">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSk-hnawzeonCUVJQbY4skSqQvyp-S5e02wPg&usqp=CAU"
                style={{ borderRadius: "50%", width: "80px", height: "80px" }}
              />
              <h5>Queen Hospital</h5>
              <p> +974 25465 2121</p>
              <div className="d-flex">
                <p style={{ fontSize: "12px", marginTop: "10px" }}>
                  {" "}
                  <MdLocationOn style={{ fontSize: "16px" }} />
                  Al Hilal West Al Emadi Business Center 3rd Floor Room No:53, C
                  Ring Rd, Doha, Qatar
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="hspd">
              <img
                src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                style={{ borderRadius: "50%", width: "80px", height: "80px" }}
              />
              <h5>Doha Clinic Hospital</h5>
              <p> +974 4465 2121</p>
              <div className="d-flex">
                <p style={{ fontSize: "12px", marginTop: "10px" }}>
                  {" "}
                  <MdLocationOn style={{ fontSize: "16px" }} />
                  Al Hilal West Al Emadi Business Center 3rd Floor Room No:53, C
                  Ring Rd, Doha, Qatar
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="hspd">
              <img
                src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                style={{ borderRadius: "50%", width: "80px", height: "80px" }}
              />
              <h5>Aster Hospital</h5>
              <p> +974 4465 2121</p>
              <div className="d-flex">
                <p style={{ fontSize: "12px", marginTop: "10px" }}>
                  {" "}
                  <MdLocationOn style={{ fontSize: "16px" }} />
                  Al Hilal West Al Emadi Business Center 3rd Floor Room No:53, C
                  Ring Rd, Doha, Qatar
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row w-100 m-auto mt-4">
          <div className="col-md-3">
            <div className="hspd">
              <img
                src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                style={{ borderRadius: "50%", width: "80px", height: "80px" }}
              />
              <h5>Al Khor Hospital</h5>
              <p> +974 4465 2121</p>
              <div className="d-flex">
                <p style={{ fontSize: "12px", marginTop: "10px" }}>
                  {" "}
                  <MdLocationOn style={{ fontSize: "16px" }} />
                  Al Hilal West Al Emadi Business Center 3rd Floor Room No:53, C
                  Ring Rd, Doha, Qatar
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="hspd">
              <img
                src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                style={{ borderRadius: "50%", width: "80px", height: "80px" }}
              />
              <h5>Al Wakrah Hospital</h5>
              <p> +974 4465 2121</p>
              <div className="d-flex">
                <p style={{ fontSize: "12px", marginTop: "10px" }}>
                  {" "}
                  <MdLocationOn style={{ fontSize: "16px" }} />
                  Al Hilal West Al Emadi Business Center 3rd Floor Room No:53, C
                  Ring Rd, Doha, Qatar
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="hspd">
              <img
                src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                style={{ borderRadius: "50%", width: "80px", height: "80px" }}
              />
              <h5>The Cuban Hospita</h5>
              <p> +974 4465 2121</p>
              <div className="d-flex">
                <p style={{ fontSize: "12px", marginTop: "10px" }}>
                  {" "}
                  <MdLocationOn style={{ fontSize: "16px" }} />
                  Al Hilal West Al Emadi Business Center 3rd Floor Room No:53, C
                  Ring Rd, Doha, Qatar
                </p>
              </div>  
            </div>
          </div>
          <div className="col-md-3">
            <div className="hspd">
              <img
                src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                style={{ borderRadius: "50%", width: "80px", height: "80px" }}
              />
              <div>
                <h5>Naseem Healthcare</h5>
                <p> +974 4465 2121</p>
                <div className="d-flex">
                  <p style={{ fontSize: "12px", marginTop: "10px" }}>
                    {" "}
                    <MdLocationOn style={{ fontSize: "16px" }} />
                    Al Hilal West Al Emadi Business Center 3rd Floor Room No:53,
                    C Ring Rd, Doha, Qatar
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hospital;
