import React from "react";
import Header from "../component/Header";
import { Button } from "react-bootstrap";

function Adddepartment() {
  return (
    <div className="web">
      <Header />
      <div className="p-3 ">
        <div className="row  mt-3">
          <h4>Add Department </h4>

          <div>
            <p>Department Name</p>
            <input className="form-control" />
          </div>
          <div>
            <p>Description</p>
            <textarea rows={10} cols={40} />
          </div>

          <div>
            <div className="row  mt-3">
              <div className="col-4">
                <label>Department Status </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label class="form-check-label" for="flexRadioDefault1">
                    Active
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    checked
                  />
                  <label class="form-check-label" for="flexRadioDefault2">
                    Inactive
                  </label>
                </div>
              </div>
            </div>
          </div>
          <Button style={{ width: "200px" }}> Create Department</Button>
        </div>
      </div>
    </div>
  );
}

export default Adddepartment;
