import React, { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePicker from "react-multi-date-picker";
import { allData } from "../pages/Constants";
import { BsCalendarDate } from "react-icons/bs";
import { BsFilter, BsDownload } from "react-icons/bs";
import Header from "../component/Header";
import { Button } from "react-bootstrap";

function Department() {
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: "Dentiest", label: "Dentiest" },
    { value: "Skin", label: "Skin" },
    { value: "Heart", label: "Heart" },
  ];
  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);

  const [collection, setCollection] = React.useState(
    cloneDeep(allData.slice(0, countPerPage))
  );

  console.log(allData);

  const searchData = React.useRef(
    throttle((val) => {
      const query = val.toLowerCase();
      setCurrentPage(1);
      const data = cloneDeep(
        allData
          .filter((item) => item.doctorname.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      setCollection(data);
    }, 400)
  );

  React.useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(allData.slice(from, to)));
  };

  const tableRows = (rowData) => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      return <td key={i}>{key[keyD]}</td>;
    });

    return <tr key={index}>{columnData}</tr>;
  };

  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>{title}</td>
    ));
  };
  const tableHead = {
    slno: "Slno",
    departantname: "Department Name",
    status: "Status",
    action: "Action",
  };
  return (
    <div className="web">
      <Header />
      <div className="p-3 ">
        <div className="mt-5">
          <a href="/adddepartment">
            <Button style={{ float: "right" }}> Add Department</Button>
          </a>
          <div>
            <h4 className="clr mb-0">Department</h4>
          </div>

          <table>
            <thead>
              <tr>{headRow()}</tr>
            </thead>
            <tbody className="trhover">{tableData()}</tbody>
          </table>
          <Pagination
            pageSize={countPerPage}
            onChange={updatePage}
            current={currentPage}
            total={allData.length}
          />
        </div>
      </div>
    </div>
  );
}

export default Department;
