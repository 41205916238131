import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Sidebar from './component/Sidebar
import Welcomepage from "./pages/Welcomepage";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Videoconsult from "./pages/Videoconsult";
import Subscriptions from "./pages/Subscriptions";
import Payment from "./pages/Payment";
import Settings from "./pages/Settings";
import Manage from "./pages/Manage";
import Contactus from "./pages/Contactus";
import Adddoctor from "./pages/Adddoctor";
import SDbar from "./pages/SDbar";
import Homevisit from "./pages/Homevisit";
import Hospital from "./pages/Hospital";
import Hospitaldetails from "./pages/Hospitaldetails";
import Doctors from "./pages/Doctors";
import Editdoctor from "./pages/Editdoctor";
import Department from "./pages/Department";
import Adddepartment from "./pages/Adddepartment";
import Addhospital from "./pages/Addhospital";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<SDbar />}>
          <Route path="/home" element={<Home />} />
          <Route path="/hospitaldetails" element={<Hospitaldetails />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/appointment" element={<Videoconsult />} />
          <Route path="/subscription" element={<Subscriptions />} />
          <Route path="/report" element={<Payment />} />
          <Route path="/setting" element={<Settings />} />
          <Route path="/manage" element={<Manage />} />
          <Route path="/contact" element={<Contactus />} />
          <Route path="/pharmacy" element={<Homevisit />} />
          <Route path="/adddoctor" element={<Adddoctor />} />
          <Route path="/hospital" element={<Hospital />}></Route>
          <Route path="/doctors" element={<Doctors />} />
          <Route path="/editdoctor" element={<Editdoctor />} />
          <Route path="/department" element={<Department />} />
          <Route path="/adddepartment" element={<Adddepartment />} />
          <Route path="/addhospital"  element={<Addhospital />}></Route>
        </Route>
        <Route path="" element={<Welcomepage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
