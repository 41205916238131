import React from "react";
import Header from "../component/Header";
import { MdLocationOn } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

function Doctors() {
  const popover = (
    <Popover id="popover-basic">
      {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
      <Popover.Body>
        <a href="/editdoctor">
          <p>edit</p>
        </a>
      </Popover.Body>
    </Popover>
  );
  return (
    <div className="web">
      <Header />
      <div className="p-3">
        <a href="/adddoctor">
          <Button style={{ float: "right" }}>ADD Doctor</Button>
        </a>
        <h4>Doctors</h4>

        <div>
          <div className="row w-100 m-auto mt-4">
            <div className="col-md-3">
              <div className="hspd">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popover}
                >
                  <img
                    src="/images/dots.png"
                    style={{
                      marginRight: "15px",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      padding: "8px",
                      position:"absolute",
                      marginLeft:"120px"
                    }}
                  />
                </OverlayTrigger>
                <img
                  src="https://img.freepik.com/free-photo/smiling-doctor-with-strethoscope-isolated-grey_651396-974.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.2.35560669.1669291340&semt=sph"
                  style={{
                    borderRadius: "50%",
                    width: "80px",
                    height: "80px",
                  }}
                />
                <h5>Dr. Saer Laika</h5>
                <span>(MBBS)</span>
                <p>5 yr experience</p>
                <p> +974 4465 2121</p>
                <p>Anesthesiology</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="hspd">
              <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popover}
                >
                  <img
                    src="/images/dots.png"
                    style={{
                      marginRight: "15px",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      padding: "8px",
                      position:"absolute",
                      marginLeft:"120px"
                    }}
                  />
                </OverlayTrigger>
                <img
                  src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                  style={{ borderRadius: "50%", width: "80px", height: "80px" }}
                />
                <h5>Dr Maher Mahmoud</h5>
                <span>(MBBS)</span>
                <p>8 yr experience</p>
                <p> +974 4465 2121</p>
                <p>Cardiology</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="hspd">
              <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popover}
                >
                  <img
                    src="/images/dots.png"
                    style={{
                      marginRight: "15px",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      padding: "8px",
                      position:"absolute",
                      marginLeft:"120px"
                    }}
                  />
                </OverlayTrigger>
                <img
                  src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                  style={{ borderRadius: "50%", width: "80px", height: "80px" }}
                />
                <h5>Dr.Prabhakara V G</h5>
                <span>(MBBS)</span>
                <p>12 yr experience</p>
                <p> +974 4465 2121</p>
                <p>Dermatology</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="hspd">
              <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popover}
                >
                  <img
                    src="/images/dots.png"
                    style={{
                      marginRight: "15px",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      padding: "8px",
                      position:"absolute",
                      marginLeft:"120px"
                    }}
                  />
                </OverlayTrigger>
                <img
                  src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                  style={{ borderRadius: "50%", width: "80px", height: "80px" }}
                />
                <h5>Dr.Omar Mashhor</h5>
                <span>(MBBS)</span>
                <p>13 yr experience</p>
                <p> +974 4465 2121</p>
                <p>Endocrinology</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="row w-100 mt-4 m-auto">
            <div className="col-md-3">
              <div className="hspd">
              <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popover}
                >
                  <img
                    src="/images/dots.png"
                    style={{
                      marginRight: "15px",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      padding: "8px",
                      position:"absolute",
                      marginLeft:"120px"
                    }}
                  />
                </OverlayTrigger>
                <img
                  src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                  style={{ borderRadius: "50%", width: "80px", height: "80px" }}
                />
                <h5>Dr.Moussa Zayed</h5>
                <span>(MBBS)</span>
                <p>5 yr experience</p>
                <p> +974 4465 2121</p>
                <p> ENT (Ear, Nose, Throat)</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="hspd">
              <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popover}
                >
                  <img
                    src="/images/dots.png"
                    style={{
                      marginRight: "15px",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      padding: "8px",
                      position:"absolute",
                      marginLeft:"120px"
                    }}
                  />
                </OverlayTrigger>
                <img
                  src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                  style={{ borderRadius: "50%", width: "80px", height: "80px" }}
                />
                <h5>Dr. Waleed Khaled</h5>
                <span>(MBBS)</span>
                <p>5 yr experience</p>

                <p> +974 4465 2121</p>
                <p> Family Medicine</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="hspd">
              <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popover}
                >
                  <img
                    src="/images/dots.png"
                    style={{
                      marginRight: "15px",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      padding: "8px",
                      position:"absolute",
                      marginLeft:"120px"
                    }}
                  />
                </OverlayTrigger>
                <img
                  src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                  style={{ borderRadius: "50%", width: "80px", height: "80px" }}
                />
                <h5>Dr. Amr Mahmoud</h5>
                <span>(MBBS)</span>

                <p> +974 4465 2121</p>
                <p>Anesthesiology</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="hspd">
              <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popover}
                >
                  <img
                    src="/images/dots.png"
                    style={{
                      marginRight: "15px",
                      borderRadius: "50%",
                      width: "40px",
                      height: "40px",
                      cursor: "pointer",
                      padding: "8px",
                      position:"absolute",
                      marginLeft:"120px"
                    }}
                  />
                </OverlayTrigger>
                <img
                  src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
                  style={{ borderRadius: "50%", width: "80px", height: "80px" }}
                />
                <h5>Dr. Saer Laika</h5>
                <span>(MBBS)</span>
                <p>13 yr experience</p>

                <p> +974 4465 2121</p>
                <p>Anesthesiology</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Doctors;
