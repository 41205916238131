import React from "react";

import { Button } from "react-bootstrap";
import Header from "../component/Header";

function Contactus() {
  return (
    <div className="web">
      <Header />
      <div className="mt-3 p-3">
        <p className="clr" style={{ fontWeight: "500", fontSize: "20px" }}>
          Contact Us
        </p>

        <input
          class="form-control"
          placeholder="Hospital Name"
          style={{ width: "455px" }}
        />

        <p style={{ marginTop: "25px" }}>
          {" "}
          <b>Message </b>
        </p>
        <textarea rows={7} cols={59} />
        <div>
          <Button style={{ width: "150px" }}>Send</Button>

          <div className="con mt-5">
            <div className="contact">
              <img src="/images/cal.jpg" style={{ width: "100px" }} />
              <p className="clr">+91 8008090802</p>
              <p className="clr">+91 8008090802</p>
            </div>

            <div className="contact">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRuVqh9yCAuJJfDYp-S7I9sF_p8W6-qTKBQ-g&usqp=CAU"
                style={{ width: "100px", marginTop: "20px" }}
              />
            </div>

            <div className="contact ">
              <img
                src="/images/gmail.png"
                style={{ width: "40px", marginTop: "25px" }}
              />
              <p className="clr">contact@gmail.com</p>
              <p className="clr">contact@mawiddi.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactus;
