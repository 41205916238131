import React, { useState } from "react";

import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePicker from "react-multi-date-picker";
import { allData } from "./Reportdata";
import { BsCalendarDate } from "react-icons/bs";
import { BsFilter, BsDownload } from "react-icons/bs";
import Header from "../component/Header";

function Payment() {
  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);

  const [collection, setCollection] = React.useState(
    cloneDeep(allData.slice(0, countPerPage))
  );
  const [clr, setclr] = useState();

  const searchData = React.useRef(
    throttle((val) => {
      const query = val.toLowerCase();
      setCurrentPage(1);
      const data = cloneDeep(
        allData
          .filter((item) => item.doctorname.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      setCollection(data);
    }, 400)
  );

  React.useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(allData.slice(from, to)));
  };

  const tableRows = (rowData) => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      return <td key={i}>{key[keyD]}</td>;
    });

    return <tr key={index}>{columnData}</tr>;
  };

  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>{title}</td>
    ));
  };
  const tableHead = {
    slno: "Slno",
    invoiceid: "invoiceid",
    Patient: "Patientname",
    paymenttype: "Paymenttype",
    paiddate: "Paiddate",
    paidamount: "Paidamount",
    paymentmode: "Paymentmode",
 
  };
  return (
    <div className="web">
      <Header />

      <div className="mt-3 p-3">
        <div>
          <div className="d-flex">
            <div class="search">
              <input
                placeholder="Search by name "
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div>
              <BsCalendarDate
                style={{
                  position: "absolute",
                  marginLeft: "50px",
                  marginTop: "15px",
                  color: "#0d6efd",
                }}
              />
              <DatePicker
                range
                plugins={[<DatePanel />]}
                placeholder="date-range"
                style={{
                  padding: "20px",
                  marginLeft: "40px",
                  paddingLeft: "30px",
                }}
              />
            </div>
            {/* <div>
              <div
                style={{
                  marginLeft: "40px",
                  border: "1px solid lightslategray",
                  padding: "7px",
                  width: "145px",
                  textAlign: "center",
                  borderRadius: "5px",
                }}
              >
                {" "}
                <BsFilter />
                filter{" "}
              </div>
            </div> */}
            <div
              className="download"
              style={{
                marginLeft: "40px",
                border: "1px solid lightslategray",
                padding: "11px",
                width: "145px",
                fontSize: "12px",
                textAlign: "center",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              <p>
                <BsDownload style={{ marginRight: "5px" }} />
                Download Report
              </p>
            </div>
          </div>
          <table>
            <thead>
              <tr>{headRow()}</tr>
            </thead>
            <tbody className="trhover">{tableData()}</tbody>
          </table>
          <Pagination
            pageSize={countPerPage}
            onChange={updatePage}
            current={currentPage}
            total={allData.length}
          />
        </div>
      </div>
    </div>
  );
}

export default Payment;
