import React from "react";
import Header from "../component/Header";
import { Button } from "react-bootstrap";

function Editdoctor() {
  return (
    <div className="web">
      <Header />
      <div className="p-3 ">
        <h4 className="text-center">Edit Doctor</h4>
        <div className="row justify-content-center">
          <div className="col-4">
            <label>First Name *</label>
            <input placeholder="Justin" class="form-control" />
          </div>
          <div className="col-4">
            <label>Last Name *</label>
            <input placeholder="Parker" class="form-control" />
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-4">
            <label>Username *</label>
            <input placeholder="Justin" class="form-control" />
          </div>
          <div className="col-4">
            <label>Email *</label>
            <input placeholder="mawiddi@gmail.com" class="form-control" />
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-4">
            <label>Password</label>
            <input type="password" class="form-control" />
          </div>
          <div className="col-4">
            <label>Confirm password</label>
            <input type="password" class="form-control" />
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-4">
            <label>DOB</label>
            <input type="date" class="form-control" />
          </div>
          <div className="col-4">
            <label>Gender </label>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                Male
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Female
              </label>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-8">
            <label>Address</label>
            <input class="form-control" style={{width:"95%"}}/>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-4">
            <label>Phone Number</label>
            <input type="number" class="form-control" />
          </div>
          <div className="col-4">
            <label>Profile Image</label>
            <input type="file" class="form-control" />
          </div>
        </div>
        <Button style={{marginLeft:"42%",width:"150px",marginTop:"20px"}}>Save</Button>
      </div>
    </div>
  );
}

export default Editdoctor;
