import { Outlet } from "react-router-dom";
import React from "react";
import Sidebar from "../component/Sidebar";

function index() {
  return (
    <div style={{display:"flex"}}>
      <Sidebar />
      <Outlet  />
    </div>
  );
}

export default index;
