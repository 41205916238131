import React from "react";
import { MdLocationOn } from "react-icons/md";
import { CiDollar } from "react-icons/ci";
import { CgProfile } from "react-icons/cg";
import { FcDepartment } from "react-icons/fc";
import { Link } from "react-router-dom";

function Hospitaldetails() {
  return (
    <div className="web">
      <div className="text-center mt-3">
        <h3 className="text-center ">Naseem Healthcare</h3>
        <img
          src="https://img.freepik.com/free-photo/empty-hallway-background_23-2149408802.jpg?size=626&ext=jpg&uid=R19754806&ga=GA1.1.35560669.1669291340&semt=sph"
          style={{ width: "350px", height: "200px" }}
        />{" "}
        <h5>Naseem Healthcare</h5>
        <div>
          <p> +974 4465 2121</p>
          <p>nassem@hospital.com</p>
        </div>
        <div className="text-center">
          <span style={{ fontSize: "12px" }}>
            {" "}
            <MdLocationOn style={{ fontSize: "16px" }} />
            Al Hilal West Al Emadi Business Center 3rd Floor Room No:53, C Ring
            Rd, Doha, Qatar
          </span>
        </div>
      </div>

      <hr />
      <div className="row justify-content-center">
        <div className="col-3 text-center">
          <div className="round">
            <CiDollar style={{ fontSize: "25px" }} />
            <h6>subscription Fee</h6>
            <span>
              {" "}
              <CiDollar />
              1500
            </span>
          </div>
        </div>

        <div className="col-3 text-center">
          <Link to="/department">
            <div className="round">
              <FcDepartment style={{ fontSize: "25px" }} />
              <h6>Departments</h6>
              <span>23</span>
            </div>
          </Link>
        </div>

        <div className="col-3 text-center">
          <Link to="/doctors">
            <div className="round">
              <CgProfile style={{ fontSize: "25px" }} />
              <h6>Doctors</h6>
              <span>250</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Hospitaldetails;
