import React, { useState } from "react";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaList,
  FaPhoneAlt,
  FaBriefcaseMedical,
  FaStethoscope,
  FaElementor,
  FaHospitalAlt
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { MdSubscriptions } from "react-icons/md";
import { BiCategoryAlt, BiMenu } from "react-icons/bi";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <FaTh />,
    },
    {
      path: "/hospital",
      name: "Hospitals",
      icon: <FaHospitalAlt />,
    },
    {
      path: "/doctors",
      name: "Doctors",
      icon: <FaStethoscope />,
    },
    {
      path: "/pharmacy",
      name: "Home visit",
      icon: <FaBriefcaseMedical />,
    },

    {
      path: "/appointment",
      name: "Video Consultation",
      icon: <FaElementor />,
    },
    {
      path: "/subscription",
      name: "Advertisement",
      icon: <MdSubscriptions />,
    },
    {
      path: "/report",
      name: "Payment",
      icon: <FaList />,
    },
    // {
    //   path: "/manage",
    //   name: "Manage",
    //   icon: <BiCategoryAlt />,
    // },
    {
      path: "/setting",
      name: "Settings",
      icon: <FiSettings />,
    },
  
  ];
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: isOpen ? "200px" : "50px" ,borderRight: "1px solid lightgray" }} className="sidebar ">
        <div
          className="top_section"
          style={{ marginLeft: isOpen ? "20px" : "0px", cursor: "pointer" }}
        >
          <div
            style={{
              display: isOpen ? "block" : "none",
              borderRadius: "50%",
              padding: "28px",
              background: "#0d6efd",
            }}
            className="logo"
          >
            <img src="/images/logo.png" style={{ width: "45px" }} />
            <p>MAWIDDI</p>
          </div>
     
        </div>
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="link"
            activeclassName="active"
          >
            <div className="icon">{item.icon}</div>
            <div
              style={{ display: isOpen ? "block" : "none" }}
              className="link_text"
            >
              {item.name}
            </div>
          </NavLink>
        ))}

        <div>
          <main style={{ width: "-webkit-fill-available" }}>{children}</main>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
