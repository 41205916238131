import React from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Login() {
  const location = useLocation();

  const hidesidebar = location.pathname === "/login " ? "hide" : "";

  return (
    <div style={{ height: "100vh" ,background:"lightgray"}}>
      <div
        className="row justify-content-center"
        style={{ alignItems: "center" }}
      >
        <div className="col-10" style={{ marginTop: "10%" }}>
          <div style={{ display: "flex" }}>
            {/* <div className="col-6 ">
              <img
                  src="/images/superadmin.png"
                  style={{ width: "100%", height: "400px" }}
                />
            </div> */}

            <div   >
              <Card style={{ boxShadow: "0px 0px 5px 2px lightgray",background:"#bbcdee",borderRadius:"25px",height:"400px", width:"350px",marginLeft:"400px"}}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "45px",
                  }}
                >
                  <img src="/images/logo.png" style={{ width: "80px" }} />
                  <h2
                    style={{
                      color: "#0d6efd",
                      marginTop: "20px",
                      marginLeft: "5px",
                    }}
                  >
                    MAWIDDI
                  </h2>
                </div>
                <div className="inputlogin">
                  <div
                    class="input-group mb-4 mt-3"
                    style={{ display: "block", width: "100%" }}
                  >
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        width: "60%",
                        marginLeft: "20%",
                        borderRadius: "10px",
                      }}
                    />
                    <input
                      type="password"
                      class="form-control "
                      placeholder="Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      style={{
                        width: "60%",
                        marginLeft: "20%",
                        marginTop: "20px",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <div class="input-group mb-3"></div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Link to="/dashboard">
                    <Button style={{ width: "200px", padding: "4px" }}>
                      Login
                    </Button>
                  </Link>
                  {/* <a href="/register">
                    <p style={{ color: "#0d6efd", cursor: "pointer" }}>
                      New user?Register
                    </p>
                  </a> */}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
