import { AiFillEye } from "react-icons/ai";
import { MdModeEditOutline,MdDelete } from "react-icons/md";
export const allData = [
    {
      slno: "0",
      departantname: "Cancer Department",
      status:<div><p style={{border:"1px solid red",padding:"5px",color:"red",width:"80px"}}>active</p></div>,
      action: "Dentiest",
     
    },
    {
      slno: "0",
      departantname: "Dentists",
      status:<div><p style={{border:"1px solid red",padding:"5px",color:"red",width:"80px"}}>active</p></div>,
      action: "Dentiest",
     
    },
    {
      slno: "0",
      departantname: "ENT Department",
      status:<div><p style={{border:"1px solid red",padding:"5px",color:"red",width:"80px"}}>active</p></div>,
      action: "Dentiest",
     
    },
    {
      slno: "0",
      departantname: "Neurology",
      status:<div><p style={{border:"1px solid green",padding:"5px",color:"green",width:"80px"}}>Inactive</p></div>,
      action: "Dentiest",
     
    },
    {
      slno: "0",
      departantname: "Opthalmology",
      status:<div><p style={{border:"1px solid red",padding:"5px",color:"red",width:"80px"}}>active</p></div>,
      action: "Dentiest",
     
    },
    {
      slno: "0",
      departantname: "Opthalmology",
      status:<div><p style={{border:"1px solid red",padding:"5px",color:"red",width:"80px"}}>active</p></div>,
      action: "Dentiest",
     
    },
  
  ];
  