import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Header from "../component/Header";
import { AiFillCamera } from "react-icons/ai";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { allData } from "../pages/Data";
import Button from "react-bootstrap/Button";

import Modal from "react-bootstrap/Modal";


function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">ADs image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p> Name :</p>
          <input type="text" />
        </div>
        <div>
          <p>ADs image</p>
          <input type="file" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Upload</Button>
      </Modal.Footer>
    </Modal>
  );
}

function Subscriptions() {
  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);

  const [collection, setCollection] = React.useState(
    cloneDeep(allData.slice(0, countPerPage))
  );
  const searchData = React.useRef(
    throttle((val) => {
      const query = val.toLowerCase();
      setCurrentPage(1);
      const data = cloneDeep(
        allData
          .filter((item) => item.doctorname.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      setCollection(data);
    }, 400)
  );

  React.useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(allData.slice(from, to)));
  };

  const tableRows = (rowData) => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      return <td key={i}>{key[keyD]}</td>;
    });

    return <tr key={index}>{columnData}</tr>;
  };

  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>{title}</td>
    ));
  };
  const tableHead = {
    slno: "Slno",
    Sliderimagename: "Ads Name",
    uploadeddate: "Uploaded date",
    uploadedby: "Uploaded by",
    imagepreview: "Ads Preview",

    action: "Action",
  };
  return (
    <div className="web">
      <Header />

      <div className="mt-3 p-3">
        <div className="row">
          <div className="col-6">
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block "
                  src="https://graphicsfamily.com/wp-content/uploads/edd/2022/04/Social-Media-Banner-Design-for-Health-and-Medical-Promotion-scaled.jpg"
                  alt="First slide"
                  style={{ width: "300px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block "
                  src="https://graphicsfamily.com/wp-content/uploads/edd/2022/04/Social-Media-Banner-Design-for-Health-and-Medical-Promotion-scaled.jpg"
                  alt="Second slide"
                  style={{ width: "300px" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block "
                  src="https://graphicsfamily.com/wp-content/uploads/edd/2022/04/Social-Media-Banner-Design-for-Health-and-Medical-Promotion-scaled.jpg"
                  alt="Third slide"
                  style={{ width: "300px" }}
                />
              </Carousel.Item>
            </Carousel>
          </div>
          <div
            className="col-6 "
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-8 mt-5" style={{ fontSize: "12px" }}>
              <div className="addhospital">
                <div>
                  <AiFillCamera
                    style={{ fontSize: "25px" }}
                    onClick={() => setModalShow(true)}
                  />
                </div>
                <p style={{ fontSize: "13px" }}>Upload Ads</p>
              </div>
              <div className="">
                <p>File Name </p>
                <p>
                  (upload assets with the dimentions 300*300px)
                  <br />
                  *Max upload size 5mb
                </p>
              </div>
              <>
                <MyVerticallyCenteredModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                />
              </>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <p className="clr mb-0">Slider Images</p>
          <table>
            <thead>
              <tr>{headRow()}</tr>
            </thead>
            <tbody className="trhover">{tableData()}</tbody>
          </table>
          <Pagination
            pageSize={countPerPage}
            onChange={updatePage}
            current={currentPage}
            total={allData.length}
          />
        </div>
      </div>
    </div>
  );
}

export default Subscriptions;
