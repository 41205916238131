export const allData = [
  {
    slno: "0",
    Patientname: "Sumanraj",
    Appstatus: "confirmed",
    consultdr: "Hashim Keyi",
    appointmentdate: "20/02/2023",
    consultfee: "200",
    paymentstatus: "Paid",
    action: "Accepted",
  },
  {
    slno: "1",
    Patientname: "Sumanraj",
    Appstatus: "pending",
    consultdr: "Hashim Keyi",
    appointmentdate: "20/02/2023",
    consultfee: "200",
    paymentstatus: "Paid",
    action: "Accepted",
  },
  {
    slno: "2",
    Patientname: "Sumanraj",
    Appstatus: "confirmed",
    consultdr: "Hashim Keyi",
    appointmentdate: "20/02/2023",
    consultfee: "200",
    paymentstatus: "Paid",
    action: "pending",
  },
  {
    slno: "3",
    Patientname: "Sumanraj",
    Appstatus: "cancelled",
    consultdr: "Hashim Keyi",
    appointmentdate: "20/02/2023",
    consultfee: "200",
    paymentstatus: "Paid",
    action: "Accepted",
  },
  {
    slno: "4",
    Patientname: "Sumanraj",
    Appstatus: "confirmed",
    consultdr: "Hashim Keyi",
    appointmentdate: "20/02/2023",
    consultfee: "200",
    paymentstatus: "Paid",
    action: "Cancelled",
  },
  {
    slno: "5",
    Patientname: "Sumanraj",
    Appstatus: "pending",
    consultdr: "Hashim Keyi",
    appointmentdate: "20/02/2023",
    consultfee: "200",
    paymentstatus: "Paid",
    action: "Accepted",
  },
  {
    slno: "5",
    Patientname: "Sumanraj",
    Appstatus: "confirmed",
    consultdr: "Hashim Keyi",
    appointmentdate: "20/02/2023",
    consultfee: "200",
    paymentstatus: "Paid",
    action: "Accepted",
  },
  {
    slno: "6",
    Patientname: "Sumanraj",
    Appstatus: "confirmed",
    consultdr: "Hashim Keyi",
    appointmentdate: "20/02/2023",
    consultfee: "200",
    paymentstatus: "Paid",
    action: "Accepted",
  },
];
