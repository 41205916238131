import { AiFillEye } from "react-icons/ai";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
export const allData = [
  {
    slno: "0",
    Sliderimagename: "Hashim Keyi",
    uploadeddate: "20/02/2023",
    uploadedby: "25895 26323",
    imagepreview: (<div><img src="https://img.freepik.com/free-psd/medical-horizontal-banner-template_23-2148940482.jpg?w=2000" style={{width:"80px",height:"30px"}}/></div>),
  
    action: (
      <div>
        {" "}
        <AiFillEye className="icon" /> <MdModeEditOutline className="edit" />{" "}
        <MdDelete className="icon" />
      </div>
    ),
  },
];
