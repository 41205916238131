import React, { useState } from "react";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { AiFillCamera } from "react-icons/ai";
import cloneDeep from "lodash/cloneDeep";
import throttle from "lodash/throttle";
import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";

import { allData } from "../pages/Data";
import Header from "../component/Header";

function Manage() {
  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { value: "Dentiest", label: "Dentiest" },
    { value: "Skin", label: "Skin" },
    { value: "Heart", label: "Heart" },
  ];
  const countPerPage = 10;
  const [value, setValue] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);

  const [collection, setCollection] = React.useState(
    cloneDeep(allData.slice(0, countPerPage))
  );

  console.log(allData);

  const searchData = React.useRef(
    throttle((val) => {
      const query = val.toLowerCase();
      setCurrentPage(1);
      const data = cloneDeep(
        allData
          .filter((item) => item.doctorname.toLowerCase().indexOf(query) > -1)
          .slice(0, countPerPage)
      );
      setCollection(data);
    }, 400)
  );

  React.useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(allData.slice(from, to)));
  };

  const tableRows = (rowData) => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      return <td key={i}>{key[keyD]}</td>;
    });

    return <tr key={index}>{columnData}</tr>;
  };

  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>{title}</td>
    ));
  };
  const tableHead = {
    slno: "Slno",
    Sliderimagename: "Sliderimagename",
    uploadeddate: "Uploaded date",
    uploadedby: "Uploaded by",
    imagepreview: "Image Preview",

    action: "Action",
  };

  return (
    <div className="web">
     <Header />
          <div className="mt-3 p-3">
            <p className="clr">Slider Images</p>

            <div>
              <p>Hospital Bio</p>
              <textarea rows={3} cols={59} />
              <div class="input-group mb-3 mt-2">
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                  placeholder="Choose Specilization"
                  className="sel"
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div className="col-5">
                <p style={{ fontSize: "13px" }}>
                  <b>Available Timings</b>
                </p>
                <div className="d-flex">
                  <div className="col-3">
                    <p style={{ fontSize: "12px" }}>Sunday</p>
                  </div>
                  <div class="form-check form-switch col-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                  <div className="col-5">
                    <p style={{ fontSize: "12px" }}>11:00 AM To 11:00 PM</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-3">
                    <p style={{ fontSize: "12px" }}>Monday</p>
                  </div>
                  <div class="form-check form-switch col-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                  <div className="col-5">
                    <p style={{ fontSize: "12px" }}>11:00 AM To 11:00 PM</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-3">
                    <p style={{ fontSize: "12px" }}>Tuesday</p>
                  </div>
                  <div class="form-check form-switch col-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                  <div className="col-5">
                    <p style={{ fontSize: "12px" }}>11:00 AM To 11:00 PM</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-3">
                    <p style={{ fontSize: "12px" }}>Wednesday</p>
                  </div>
                  <div class="form-check form-switch col-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                  <div className="col-5">
                    <p style={{ fontSize: "12px" }}>11:00 AM To 11:00 PM</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-3">
                    <p style={{ fontSize: "12px" }}>Thursday</p>
                  </div>
                  <div class="form-check form-switch col-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                  <div className="col-5">
                    <p style={{ fontSize: "12px" }}>11:00 AM To 11:00 PM</p>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="col-3">
                    <p style={{ fontSize: "12px" }}>Saturday</p>
                  </div>
                  <div class="form-check form-switch col-2">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                  <div className="col-5">
                    <p style={{ fontSize: "12px" }}>11:00 AM To 11:00 PM</p>
                  </div>
                </div>
                <Button style={{ width: "150px" }}>Save</Button>
              </div>

              <div
                className="col-5 "
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div className="col-8 mt-5" style={{ fontSize: "12px" }}>
                  <div className="addhospital">
                    <div>
                      <AiFillCamera style={{ fontSize: "25px" }} />
                    </div>
                    <p style={{ fontSize: "13px" }}>Upload Banner Image</p>
                  </div>
                  <div className="">
                    <p>File Name </p>
                    <p>
                      (upload assets with the dimentions 300*300px)
                      <br />
                      *Max upload size 5mb
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <p className="clr mb-0">Slider Images</p>
              <table>
                <thead>
                  <tr>{headRow()}</tr>
                </thead>
                <tbody className="trhover">{tableData()}</tbody>
              </table>
              <Pagination
                pageSize={countPerPage}
                onChange={updatePage}
                current={currentPage}
                total={allData.length}
              />
            </div>
          </div>
       
    </div>
  );
}

export default Manage;
